html {
    font-size: 62.5%;
}

body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 1.6rem;
    color: #313843;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
	letter-spacing: .16rem;
	word-spacing: .32rem;
    color: #313843;
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3.2rem;
}

h4 {
    font-size: 2.4rem;
}

p, ul {
	font-family: "Open Sans", sans-serif;
}

p {
    font-size: 1.6rem;
    line-height: 1.8em;
    padding: 0.8rem 0;
    margin: 0;
    text-align: justify;
}

ul {
    padding: 0.8rem 0 0.8rem 1.7rem;
    margin: 0;
    line-height: 1.8em;
}

a {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    letter-spacing: 0.16rem;
    color: #313843;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.row {
    --bs-gutter-x: 2.4rem;
}

section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scroll-snap-align: center;
}

.ReactModal__Body--open {
    overflow: hidden;
}

/*main body*/
.malehead-cls-1{fill:#d3ab84;}
.malehead-cls-2{fill:#dbb99a;}
.malehead-cls-3{fill:#231f20;}
.maleupperbody-cls-1{fill:#dbba9b;}
.maleupperbody-cls-2{fill:#d3ab84;}
.maledownbody-cls-1{fill:#d3ab84;}
.maledownbody-cls-2{fill:#dbb99a;}
.maledownbody-cls-3{fill:#edebea;}
.maledownbody-cls-4{fill:#dfdfde;}
.maledownbody-cls-5{fill:#493829;}
.maledownbody-cls-6{fill:#644c34;}
.maledownbody-cls-7{fill:#3f2d1c;}
.maledownbody-cls-8{fill:#5a4128;}

.femalehead-cls-1{fill:#ddbe9a;}
.femalehead-cls-2{fill:#e2c9b5;}
.femalehead-cls-3{fill:#262626;}
.femaleupperbody-cls-1{fill:#e2c9b5;}
.femaleupperbody-cls-2{fill:#ddbe9a;}
.femaledownbody-cls-1{fill:#ddbe9a;}
.femaledownbody-cls-2{fill:#e6ceb4;}
.femaledownbody-cls-3{fill:#edebea;}
.femaledownbody-cls-4{fill:#dfdfde;}
.femaledownbody-cls-5{fill:#212222;}
.femaledownbody-cls-6{fill:#1d1d1d;}
.femaledownbody-cls-7{fill:#343436;}

/*clothing category*/
.tanktop-cls-1{fill:#52c3f0;}
.tanktop-cls-2{fill:#00aeef;}

.tshirt-cls-1{fill:#f7be79;}
.tshirt-cls-2{fill:#f7941d;}

.shirt-cls-1{fill:#c2b59b;}
.shirt-cls-2{fill:#a09580;}
.shirt-cls-3{fill:#231f20;}

.sweater-cls-1{fill:#fbb040;}
.sweater-cls-2{fill:#b07b2d;}
.sweater-cls-3{fill:#8656a3;}
.sweater-cls-4{fill:#262262;}

.suit-cls-1{fill:#fff;}
.suit-cls-2{fill:#231f20;}
.suit-cls-3{fill:#414042;}
.suit-cls-4{fill:#554b4d;}

.outerwear-cls-1{fill:#fff;}
.outerwear-cls-2{fill:#231f20;}
.outerwear-cls-3{fill:#504749;}
.outerwear-cls-4{fill:#435a85;}
.outerwear-cls-5{fill:#d1d3d4;}
.outerwear-cls-6{fill:#414042;}

.dress-cls-1{fill:#fff;}
.dress-cls-2{fill:#99262a;}
.dress-cls-3{fill:#6c1b1e;}
.dress-cls-4{fill:#ddbe9a;}
.dress-cls-5{fill:#e6ceb4;}

/*neckline shape*/
.folded-cls-1{fill:#58595b;}
.folded-cls-2{fill:#414042;}

.vshape-cls-1{fill:#58595b;}
.vshape-cls-2{fill:#d3ab84;}
.vshape-cls-3{fill:#dbba9b;}

.round-cls-1{fill:#6d6e71;}
.round-cls-2{fill:#d3ab84;}
.round-cls-3{fill:#dbb99a;}

/*scarf, hat, glasses, necktie*/
.scarf-cls-1{fill:#27aae1;stroke:#27aae1;}
.scarf-cls-1,.scarf-cls-2{stroke-miterlimit:10;}
.scarf-cls-2{fill:#1b75bc;stroke:#1b75bc;}

.malehat-cls-1{fill:#181823;}
.malehat-cls-2{fill:#a7a9ac;}

.femalehat-cls-1{fill:#d77d35;}
.femalehat-cls-2{fill:#fbb378;}
.femalehat-cls-3{fill:#bee3d8;}
.femalehat-cls-4{fill:#f15d5c;}
.femalehat-cls-5{fill:#48b896;}

.maleglasses-cls-1{fill:#e6e7e8;}
.maleglasses-cls-2{fill:#323232;}

.femaleglasses-cls-1{fill:#414042;}
.femaleglasses-cls-2{fill:#323232;}

.necktie-cls-1{fill:#231f20;}
.necktie-cls-2{fill:#353433;}

/*clothing pattern*/
.floral-cls-1{fill:rgba(241, 242, 242, 0.9);}

.spotted-cls-1{fill:rgba(241, 242, 242, 0.8);}

.graphics-cls-1{fill:none;}
.graphics-cls-1,.graphics-cls-2{stroke:rgba(241, 242, 242, 0.9);stroke-linecap:round;stroke-linejoin:round;}

.striped-cls-1{fill:rgba(241, 242, 242, 0.8);}

.plaid-cls-1{fill:rgba(241, 242, 242, 0.3);}
.plaid-cls-2{fill:none;}