/*************/
/** general **/
/*************/

.center {
    text-align: center;
}

/*************************/
/** container & spacing **/
/*************************/

.contentWrapper {
    padding-left: 11.2rem;
    position: relative;
}

.projectContent {
    padding-left: 8.4rem;
    padding-right: 8.4rem;
    padding-bottom: 12rem;
}

/*************/
/** sidebar **/
/*************/

.sidebar {
    width: 11.2rem;
    height: 100vh;
    position:fixed;
    background-color: #000;
    z-index: 9999;
}

.logo {
    padding-top: 4.8rem;
    padding-bottom: 8rem;
}

.navSpace {
    margin-top: 3.6rem;
}

.navText a {
    color: #fff;
    font-size: 1.2rem;
    position: relative;
}

.navText a:hover {
    text-decoration: none;
    color: #fff;
}

.navText a:after {
    content: "";
    width: 0;
    height: 0.2rem;
    border-radius: 1.0rem;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    transition: width .2s ease-in-out;
}

.navText a:hover:after {
    opacity: 1;
    width: 6.8rem;
}

.contactContainer {
    width: 100%;
    position: absolute;
    bottom: 4.8rem;
}

.contact {
    margin-top: 3.2rem;
    text-align: center;
}

.contactContainer .contact a {
    color: #fff;
    font-size: 2.0rem;
    position: relative;
}

.contactContainer .contact a:hover {
    text-decoration: none;
    color: #fff;
}

.contactContainer .contact a:after {
    content: "";
    width: 0;
    height: 0.2rem;
    border-radius: 1.0rem;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    transition: width .2s ease-in-out;
}

.contactContainer .contact a:hover:after {
    opacity: 1;
    width: 3.0rem;
}

.back {
    padding-top: 4.8rem;
    padding-bottom: 8rem;
}

.back a {
    color: #fff;
    font-size: 2.0rem;
}

.back svg {
    transition: all .2s ease-in-out;
}

.back svg:hover {
    transform: scale(1.4);
}

/************/
/** footer **/
/************/

.copyright {
    font-family: "Montserrat", sans-serif;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 0.8rem;
}

/***********/
/** about **/
/***********/

.aboutBoldText {
    font-weight: 700;
    font-size: 2.4rem;
    margin: 1.6rem 0;
}

.gallery {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
}

.imgRadius {
    border-radius: 1.2rem;
}

/*********************/
/** welcome section **/
/*********************/

.homepageContainer {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
    padding-left: 11.2rem;
}

.intro {
    position: relative;
    padding: 0 9.6rem;
}

.imgCompImgContainer {
    position: relative;
    width: 100rem;
    padding: 0;
}

.imgCompImg {
    width: 100%;
    overflow: hidden;
    margin: 0;
}

.imgCompImg img {
    display:block;
    vertical-align:middle;
}

.imgCompOverlay {
    position: absolute;
    top: 0;
    left: 0;
}

.imgCompOverlay img {
    background-color: #fff;
}

.jobTitle {
    margin: 9.6rem 0;
    text-align: center;
}

.jobTitle span {
    font-family: "Montserrat", sans-serif;
    font-size: 2.4rem;
    font-weight: 300;
    color: #313843;
    letter-spacing: .16rem;
}

.nextPage {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 9.6rem;
    text-align: center;
}

/**************************/
/** project list section **/
/**************************/

.projectList {
    height: 100%;
    position: relative;
}

.titleContainer {
    padding-top: 18rem;
    padding-bottom: 4rem;
    text-align: center;
}

.horizontalProject {
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-left: 11.2rem;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.horizontalProject::-webkit-scrollbar {
  display: none;
}

.horizontalProject a:first-child {
    margin-left: 0;
}

.horizontalProject a:last-child {
    margin-right: 11.2rem;
}

.projectContainer {
    width: 32rem;
    border-radius: 1.5rem;
    box-shadow: 0 0 6rem 0 rgba(0, 0, 0, 0.3);
    margin-left: 4.8rem;
    display: inline-block;
    transition: all .2s ease-in-out;
}

.projectContainer:hover {
    transform: scale(1.1);
}

/*********************/
/** project general **/
/*********************/

.titleSpace {
    padding-top: 12rem;
    padding-bottom: 12rem;
    text-align: center;
}

.subsectionTitleSpace {
    padding-bottom: 2.4rem;
}

.picSpace {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
}

.picStyle {
    box-shadow: 0 5px 20px 0 #d8d8d8;
    border-radius: 1.2rem;
}

.stackGroup {
    display: flex;
    flex-direction: column;
    gap: 6.4rem;
}

.bold {
    font-weight: 700;
}

/**************************/
/** project image button **/
/**************************/

.projectImgBtnWrapper {
    width: 24rem;
    position: absolute;
    top: 85%;
    left: 30%;
}

.projectImgBtn {
    width: 100%;
    height: 4rem;
    background-color: #fff;
    border: solid .1rem #000;
    border-radius: 3rem;
    font-weight: 700;
    color: #000;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2);
    transition: all .2s ease-in-out;
    cursor: pointer;
    animation-name: breathing;
    animation-duration: 1.5s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    -webkit-animation-name: breathing;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-direction: alternate;
}

@-webkit-keyframes breathing {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}

@keyframes breathing {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}

.projectImgBtn:hover {
    animation: 0;
    transform: scale(1.1);
}

.projectImgBtn:focus {
    outline: 0;
}

/*******************/
/** problem block **/
/*******************/

.problemBlock {
    background-color: #f2f2f2;
    padding: 4.8rem;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
}

.problemText1 {
    text-align: center;
    margin-bottom: 1.6rem;
}

.problemText2 {
    font-family: "Libre Baskerville", serif;
    font-weight: 400;
	letter-spacing: .048rem;
	word-spacing: normal;
    line-height: 3.6rem;
    color: #313843;
    text-align: center;
}

/********************/
/** text highlight **/
/********************/

.highlight {
    background-image: linear-gradient(to right, transparent 50%, transparent 50%);
    background-size: 200%;
    background-position: 0 0;
    transition: 0.5s ease-in-out;
}

.highlightActive {
    background-position: -100% 0;
}

/*************/
/** divider **/
/*************/

.dividerContainer {
    padding-left: 8.4rem;
    padding-right: 8.4rem;
}

.sectionDivider {
    height: 0.2rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #000000 50%, rgba(0, 0, 0, 0));
}

/*****************/
/** pros & cons **/
/*****************/

.prosConsTitle {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    margin-top: 1.6rem;
}

.prosConsTitle svg {
    color: #fc5759;
}

.prosConsTitle p {
    color: #fc5759;
}

.prosConsList {
    list-style-type: none;
    margin: 0.8rem 0 0 3.2rem;
    padding: 0;
}

.prosConsList li {
    padding: 0.8rem 0;
}

/*********************/
/** previous & next **/
/*********************/

.previousPageLink svg {
    margin-right: 2rem;
    transition: all .2s ease-in-out;
}

.previousPageLink a:hover {
    color: #313843;
}

.previousPageLink a:hover svg {
    color: #313843;
    transform: scale(1.4);
}

.nextPageLink {
    text-align: right;
}

.nextPageLink svg {
    margin-left: 2rem;
    transition: all .2s ease-in-out;
}

.nextPageLink a:hover {
    color: #313843;
}

.nextPageLink a:hover svg {
    color: #313843;
    transform: scale(1.4);
}

/***********/
/** video **/
/***********/

.videoFeature {
    width: 100%;
    border-radius: 0.4rem;
    box-shadow: 0 5px 20px 0 #d8d8d8;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

.videoStack {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
}

/*****************/
/** image modal **/
/*****************/

.modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: zoom-out;
}

.modalContent {
    outline: none;
}

.modalContent img {
    border-radius: 1.2rem;
}