/************/
/** filter **/
/************/

.filterContainer {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    border: solid 0.2rem #fff;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
    display: inline-block;
    position: relative;
}

.filterIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.filterActive {
    border: solid 2px #313843;
}

.filterTitleContainer {
    display: flex;
    margin-bottom: 0.8rem;
}

.filterItemContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
}

/***********/
/** glyph **/
/***********/

.glyphContainer {
    padding-top: 6.8rem;
}

.glyph {
    height: 315px;
    position: relative;
    display: flex;
    justify-content: center;
}

.headPosition {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.upperbodyPosition {
    position: absolute;
    top: 43.1%;
    left: 50%;
    transform: translate(-50%, 0);
}

.downbodyPosition {
    position: absolute;
    top: 80.6%;
    left: 50%;
    transform: translate(-50%, 0);
}

.tanktopPosition {
    position: absolute;
    top: 43.1%;
    left: 50%;
    transform: translate(-50%, 0);
}

.tshirtPosition {
    position: absolute;
    top: 43.1%;
    left: 50%;
    transform: translate(-50%, 0);
}

.shirtPosition {
    position: absolute;
    top: 43.1%;
    left: 50%;
    transform: translate(-50.5%, 0);
}

.sweaterPosition {
    position: absolute;
    top: 43.1%;
    left: 50%;
    transform: translate(-50%, 0);
}

.suitPosition {
    position: absolute;
    top: 43.1%;
    left: 50%;
    transform: translate(-50%, 0);
}

.outerwearPosition {
    position: absolute;
    top: 40.3%;
    left: 50%;
    transform: translate(-50%, 0);
}

.dressPosition {
    position: absolute;
    top: 43.1%;
    left: 50%;
    transform: translate(-50%, 0);
}

.foldedPosition {
    position: absolute;
    top: 39.5%;
    left: 50%;
    transform: translate(-50%, 0);
}

.vshapePosition {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, 0);
}

.roundPosition {
    position: absolute;
    top: 41.7%;
    left: 50%;
    transform: translate(-50%, 0);
}

.scarfPosition {
    position: absolute;
    top: 39.5%;
    left: 50%;
    transform: translate(-50%, 0);
}

.malehatPosition {
    position: absolute;
    top: -4.3%;
    left: 50%;
    transform: translate(-50%, 0);
}

.femalehatPosition {
    position: absolute;
    top: -1.7%;
    left: 50%;
    transform: translate(-50%, 0);
}

.maleglassesPosition {
    position: absolute;
    top: 17.5%;
    left: 50%;
    transform: translate(-50%, 0);
}

.femaleglassesPosition {
    position: absolute;
    top: 22.5%;
    left: 50%;
    transform: translate(-50%, 0);
}

.necktiePosition {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, 0);
}